<template>
    <div>
        <GmapMap
            ref="mapRef"
            :center="map_center"
            @center_changed="$_updateCenter"
            :zoom="16"
            :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            }"
            class="map"
        >
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :width="10"
                :icon="{
                    url: require('../assets/imgs/svg/0603-b.svg'),
                    scaledSize: { width: 30, height: 40 },
                }"
                @click="setStore(m)"
            />
        </GmapMap>
        <div>
            <div class="box">
                <!-- <img
          class="small-button"
          :src="require('@/assets/imgs/svg/0603-g.svg')"
          @click="$_href('members')"
        /> -->
                <!-- <vueper-slides ref="adBanner" class="carousel-ad no-shadow" autoplay fixed-height="120px" :arrows="false">
					<vueper-slide v-for="item in ad" :key="item.id" :image="`https://s3lvelect.s3.ap-east-1.amazonaws.com/${item.photo}`" :link="item.link" />
				</vueper-slides> -->
                <van-swipe :autoplay="3000" indicator-color="white">
                    <van-swipe-item v-for="item in ad" :key="item.id">
                        <van-image
                            height="124px"
                            width="355px"
                            @click="goLink(item.link)"
                            :src="`https://s3lvelect.s3.ap-east-1.amazonaws.com/${item.photo}`"
                        ></van-image>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div
                class="light-box"
                :class="focus ? show : hide"
                @click.self="focus = false"
            ></div>
            <div
                class="footer flex-container flex-column container-pa16 width-fill flex-center"
                style="z-index: 31"
            >
                <div
                    class="margin-bottom-8 place-inform"
                    :class="focus ? fillwidth : fill90"
                    @click="$_goToStore(store.id)"
                >
                    <div
                        class="content flex-container"
                        :class="focus ? show : hide"
                    >
                        <div
                            class="column-5 flex-column"
                            :class="focus ? show : hide"
                        >
                            <span class="text-2000 text-b padding-v-6">{{
                                store.name
                            }}</span>
                            <div class="flex-container padding-v-6 flex-center">
                                <img
                                    class="front-img-w20"
                                    :src="
                                        require('@/assets/imgs/svg/0603-i.svg')
                                    "
                                />
                                <span class="text-875">{{
                                    store.address
                                }}</span>
                            </div>
                            <div class="flex-container padding-v-6 flex-center">
                                <img
                                    class="front-img-w20"
                                    :src="
                                        require('@/assets/imgs/svg/0527-l.svg')
                                    "
                                />
                                <span class="text-875">{{ store.time }}</span>
                            </div>
                        </div>
                        <div class="column-1" :class="focus ? show : hide">
                            <div @click.stop="$_goToNavi(store)">
                                <img
                                    class="small-button"
                                    :src="
                                        require('@/assets/imgs/svg/0603-k.svg')
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="bottom center" :class="focus ? show : hide">
                        <span class="text-1250"
                            >可借：{{ store.forBorrow }} / 可還：{{
                                store.forGiveBack
                            }}</span
                        >
                    </div>
                </div>
                <img
                    class="normal-button"
                    @click="toScanner('scanner')"
                    :src="require('@/assets/imgs/svg/0603-m.svg')"
                />
                <div
                    class="flex-container flex-center space-between width-fill"
                >
                    <!-- <img
            class="small-button"
            @click="setCenter()"
            :src="require('@/assets/imgs/svg/0603-k.svg')"
          /> -->
                    <img
                        class="small-button"
                        @click="$_href('members')"
                        :src="require('@/assets/imgs/svg/0603-g.svg')"
                    />
                    <button
                        type="button"
                        class="capsule-button text-1250 capsule-giant capsule-radius-20 bg-text-color-darkgray"
                        @click="$_href('search')"
                    >
                        選擇附近店家租借
                    </button>

                    <img
                        class="small-button"
                        @click="setCenter()"
                        :src="require('@/assets/imgs/svg/0603-l.svg')"
                    />
                </div>
            </div>
        </div>
        <van-dialog
            v-model="showAd"
            title="租借成功"
            confirmButtonText="確認"
            @confirm="confirm"
        >
            <img
                style="
                    text-algin: center;
                    width: 100%;
                    height: auto;
                    margin-top: 20px;
                "
                @click="openUrl"
                :src="this.advImg"
            />
        </van-dialog>

        <van-dialog
            v-if="this.isClose"
            v-model="showToady"
            :showConfirmButton="false"
            :width="250"
        >
            <van-image :src="this.todayAdvImg" @click="goTodayAd" />
            <van-checkbox
                style="margin: 10px"
                v-model="isCheck"
                @change="readChange"
                >今日不再顯示。
                <template #icon="props">
                    <van-image
                        width="18px"
                        height="18px"
                        :src="props.checked ? activeIcon : inactiveIcon"
                    />
                </template>
            </van-checkbox>
            <div class="confirm" @click="closeDialog">關閉</div>
        </van-dialog>
    </div>
</template>

<script>
// import SockJS from "sockjs-client";
// import Stomp from "stompjs";
import { handleErr } from "@/helpers";
import { Toast } from "vant";
import { Dialog } from "vant";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// import { Client } from "@/utils/socket.js";

// var socketUrl =
// 			process.env.VUE_APP_API_BASE_URL + "/rent-socket2";

// 		socketUrl = socketUrl
// 			.replace("https", "wss")
// 			.replace("http", "ws");
// 		console.log("socketUrl: ", socketUrl);

const firebaseConfig = {
    apiKey: "AIzaSyDxPJAZaIpyrZmtRzzNNjCXTcTBTOJjYOM",
    authDomain: "lvdian-339303.firebaseapp.com",
    projectId: "lvdian-339303",
    storageBucket: "lvdian-339303.appspot.com",
    messagingSenderId: "408081585217",
    appId: "1:408081585217:web:05c6bf18d23cf7770622c0",
    measurementId: "G-6S44RLZW9K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log("analytics: ", analytics);

export default {
    beforeCreate() {},
    created() {
        // console.log(navigator.geolocation, "navigator.geolocation");
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.setGeolocation);
        }
        // const setMarkers = async () => {
        //     try {
        //         console.log("this.map_center", this.map_center);
        //         const res = await this.$axios.get(
        //             `customer/store/index/${this.map_center.lng}/${this.map_center.lat}`
        //         );
        //         console.log("res", res);
        //         this.markers = res.data.result.map((marker) => ({
        //             position: {
        //                 lat: marker.latitude,
        //                 lng: marker.longitude,
        //             },
        //             ...marker,
        //         }));
        //     } catch (error) {
        //         console.log("error", error);
        //         handleErr(error);
        //     }
        // };
        // setMarkers();
        // this.global_websocket_connect(); // websocket建立链接
    },

    destroyed() {
        window.clearInterval(this.timer);
        // window.clearInterval(this.timer2);
    },

    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }

        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
        });

        // const ws = new Client({});
        // ws.setHeader();
        // ws.createConnect();

        // this.initWebSocket();

        this.scannerResult = this.$route.params.id;
        console.log("扫描结果---" + this.scannerResult);
        if (this.scannerResult) {
            this.deviceNo = this.scannerResult.substring(
                this.scannerResult.indexOf("mp/") + 3
            );
        }

        console.log("this.deviceNo: ", this.deviceNo);
        // var socket = new SockJS(
        // 	process.env.VUE_APP_API_BASE_URL + "/rent-socket2"
        // );

        // const stompClient = Stomp.over(socket);
        // stompClient.connect({}, function () {
        // 	stompClient.subscribe("/user/queue/rent", function (data) {
        // 		console.log("data: ", data);
        // 	});
        // });
        // this.showAdDialog();

        this.getTodayAd();
        this.getUserInfo();
        this.setBanners();
        if (this.deviceNo) {
            this.powerBank(this.deviceNo);
        }
        // this.loopResultBack();
        const eventParams = {
            // button_id: "google_signup_button",
            // location: "header",
            WEB_CLICK_AD:"click首頁廣告"
        };
        this.logCustomEvent("WEB_CLICK_AD_EVENT", eventParams);
    },

    data() {
        return {
            isLoading: true,
            ad: [],
            store: {
                id: null,
                name: "",
                time: "",
                address: "",
                borrow: "",
                return: "",
                position: {},
            },
            map: null,
            focus: false,
            show: "display",
            hide: "no-display",
            fill90: "width-90",
            fillwidth: "width-fill",
            map_center: {
                // lat:25,
                // lng:121
                lat: 25.1325, //validValueError: setCenter: not a LatLng or LatLngLiteral with finite coordinates: in property lat: not a number
                lng: 121.76311,
            },
            markers: [],
            deviceNo: "",
            count: 0,
            scannerResult: "",
            websock: null,
            stompClient: "",
            timer: "",
            timer2: "",
            showAd: false,
            advUrl: "",
            advImg: "",
            showToady: false,
            activeIcon: require("@/assets/imgs/png/icon_agree_selected.png"),
            inactiveIcon: require("@/assets/imgs/png/icon_login_default.png"),
            isCheck: false,
            todayAdvImg: "", //彈窗的圖片
            todayPlatformAdvLink: "", //彈窗的鏈接
            currentDate: "",
            isClose: false,
        };
    },
    methods: {
        async setGeolocation(position) {
            console.log("position: ", position);
            this.map_center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            try {
                const res = await this.$axios.get(
                    `customer/store/index/${this.map_center.lng}/${this.map_center.lat}`
                );
                this.markers = res.data.result.map((marker) => ({
                    position: {
                        lat: marker.latitude,
                        lng: marker.longitude,
                    },
                    ...marker,
                }));
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async $_updateCenter(e) {
            let drag_distance = 0.05;
            let lat = e.lat();
            let lng = e.lng();
            if (
                Math.abs(lat - this.map_center.lat) > drag_distance ||
                Math.abs(lng - this.map_center.lng) > drag_distance
            ) {
                this.map_center = {
                    lat,
                    lng,
                };

                try {
                    const res = await this.$axios.get(
                        `customer/store/index/${lng}/${lat}`
                    );
                    let stores = res.data.result;
                    let current_marker_ids = this.markers.map(
                        (marker) => marker.storeInfoId
                    );
                    stores.forEach((store) => {
                        if (!current_marker_ids.includes(store.storeInfoId)) {
                            this.markers = [...this.markers].concat({
                                position: {
                                    lat: store.latitude,
                                    lng: store.longitude,
                                },
                                ...store,
                            });
                        }
                    });
                } catch (error) {
                    // console.log("updateCenter", error);
                    handleErr({ ...error, app: this.$parent });
                }
            }
        },
        setCenter() {
            console.log("112233");
            console.log("2233" + this.map);
            console.log("3344" + this.map_center);
            if (!this.map) return;
            this.map.panTo(this.map_center);
        },
        async setStore(marker) {
            const timeFormat = "hh:mm";
            try {
                const res = await this.$axios.get(
                    `customer/store/detail?storeInfoId=${marker.storeInfoId}`
                );
                console.log(res, "setStore");
                if (res.data.success) {
                    this.focus = true;
                    const store = res.data.result.storeInfoDto;
                    const isOpen = this.$moment().isBetween(
                        this.$moment(store.beginTime, timeFormat),
                        this.$moment(store.endTime, timeFormat)
                    );
                    this.store = {
                        id: store.storeInfoId,
                        name: store.storeName,
                        time: `${store.beginTime}-${store.endTime}${
                            isOpen ? "(營業中)" : "(休息中)"
                        }`,
                        address: store.address,
                        forBorrow: store.unNum,
                        forGiveBack: store.total - store.unNum,
                        position: marker.position,
                    };
                } else {
                    throw res;
                }
            } catch (error) {
                console.log("setStore", error);
                handleErr({ ...error, app: this.$parent });
            }
        },
        $_goToStore(storeInfoId) {
            this.$router.push(`store/${storeInfoId}`);
        },
        $_href(page) {
            // window.location.href = "/" + page;
            this.$router.push(page);
        },
        async toScanner() {
            try {
                this.deviceNo = "";
                await this.$_validateCustomer();
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        goLink(val) {
            window.location.href = val;
        },
        goTodayAd() {
            this.showToady = false;
            window.location.href = this.todayPlatformAdvLink;
        },
        readChange(val) {
            console.log("val: ", val);
            if (val) {
                this.isCheck = true;
                //年
                let year = new Date().getFullYear();
                //月份是从0月开始获取的，所以要+1;
                let month = new Date().getMonth() + 1;
                //日
                let day = new Date().getDate();

                this.currentDate = year + "-" + month + "-" + day;
                // console.log("this.currentDate: ", this.currentDate);

                localStorage.setItem("currentDate", this.currentDate);
            } else {
                this.isCheck = false;
                this.currentDate = "";
                console.log("this.currentDate: ", this.currentDate);

                localStorage.setItem("currentDate", "");
            }
        },
        async getTodayAd() {
            try {
                const res = await this.$axios.get(
                    "manager/banner/getNoticeImg"
                );
                if (res.data.success) {
                    // console.log("res: ", res);
                    this.todayAdvImg =
                        "https://s3lvelect.s3.ap-east-1.amazonaws.com/" +
                        res.data.result.advImg;
                    // console.log("this.todayAdvImg: ", this.todayAdvImg);
                    this.isClose = res.data.result.advImg === "" ? false : true;
                    this.todayPlatformAdvLink = res.data.result.platformAdvLink;
                    let year = new Date().getFullYear();
                    let month = new Date().getMonth() + 1;
                    let day = new Date().getDate();
                    let currentDate = year + "-" + month + "-" + day;
                    // console.log("currentDate: ", currentDate);
                    let date = localStorage.getItem("currentDate");
                    if (date != currentDate) {
                        this.showToady = true;
                    }
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },

        async setBanners() {
            try {
                const res = await this.$axios.get("customer/store/banners");
                console.log("res: ", res);
                if (res.data.success) {
                    this.ad = res.data.result.map((banner) => ({
                        id: banner.id,
                        photo: banner.url,
                        link: banner.link,
                    }));
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        $_goToNavi(store) {
            window.location.href = `https://www.google.com/maps/dir/?api=1&origin=&destination=${store.position.lat},${store.position.lng}`;
        },
        closeDialog() {
            this.showToady = false;
        },
        // initWebSocket() {
        // 	if (typeof WebSocket == "undefined") {
        // 		console.log("您的浏览器不支持WebSocket");
        // 	} else {
        // 		console.log("您的浏览器支持WebSocket");
        // 		//实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
        // 		//等同于socket = new WebSocket("ws://localhost:8888/xxxx/im/25");
        // 		//var socketUrl="${request.contextPath}/im/"+$("#userId").val();
        // 		var socketUrl =
        // 			process.env.VUE_APP_API_BASE_URL + "/rent-socket2";

        // 		socketUrl = socketUrl
        // 			.replace("https", "wss")
        // 			.replace("http", "ws");
        // 		console.log("socketUrl: ", socketUrl);

        // 		if (socket != null) {
        // 			socket.close();
        // 			socket = null;
        // 		}
        // 		socket = new WebSocket(socketUrl);
        // 		//打开事件
        // 		socket.onopen = () => {
        // 			console.log("已连接");
        // 			//socket.send("这是来自客户端的消息" + location.href + new Date());
        // 		};
        // 		//获得消息事件
        // 		socket.onmessage = (msg) => {
        // 			console.log("onmessage---服务器返回---" + msg);
        // 		};
        // 		//关闭事件
        // 		socket.onclose = () => {
        // 			console.log("连接已关闭");
        // 		};
        // 		//发生了错误事件
        // 		socket.onerror = function () {
        // 			console.log("连接发生了错误");
        // 		};
        // 	}
        // },

        // initWebSocket() {
        // 	this.connection();
        // 	let that = this;
        // 	// 断开重连机制,尝试发送消息,捕获异常发生时重连
        // 	this.timer = setInterval(() => {
        // 		try {
        // 			that.stompClient.send("test");
        // 		} catch (err) {
        // 			console.log("断线了: " + err);
        // 			that.connection();
        // 		}
        // 	}, 5000);
        // },
        // connection() {
        // 	// var socketUrl =process.env.VUE_APP_API_BASE_URL.replace(
        // 	// 		"https://",
        // 	// 		"wss://"
        // 	// 	).replace("http://", "ws://") + "/rent-socket2";

        // 	var socketUrl = process.env.VUE_APP_API_BASE_URL + "/rent-socket2";
        // 	// console.log("socketUrl: ", socketUrl);

        // 	// 建立连接对象
        // 	let socket = new SockJS(socketUrl);
        // 	// 获取STOMP子协议的客户端对象
        // 	this.stompClient = Stomp.over(socket);
        // 	// 定义客户端的认证信息,按需求配置
        // 	let headers = {
        // 		// Authorization: "",
        // 	};
        // 	// 向服务器发起websocket连接
        // 	this.stompClient.connect(
        // 		headers,
        // 		() => {
        // 			this.stompClient.subscribe(
        // 				"/user/queue/rent",
        // 				(msg) => {
        // 					// 订阅服务端提供的某个topic
        // 					console.log("广播成功");
        // 					console.log(msg); // msg.body存放的是服务端发送给我们的信息
        // 				},
        // 				headers
        // 			);
        // 			this.stompClient.send(
        // 				"/app/chat.addUser",
        // 				headers,
        // 				JSON.stringify({ sender: "", chatType: "JOIN" })
        // 			); //用户加入接口
        // 		},
        // 		(err) => {
        // 			// 连接发生错误时的处理函数
        // 			console.log("失败");
        // 			console.log(err);
        // 		}
        // 	);
        // }, //连接 后台
        // disconnect() {
        // 	if (this.stompClient) {
        // 		this.stompClient.disconnect();
        // 	}
        // }, // 断开连接

        initWebSocket: function () {
            // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
            // var userId = store.getters.userInfo.id;
            var url =
                process.env.VUE_APP_API_BASE_URL.replace(
                    "https://",
                    "wss://"
                ).replace("http://", "ws://") + "/rent-socket2";
            console.log(url);
            this.websock = new WebSocket(url);
            this.websock.onopen = this.websocketOnopen;
            this.websock.onerror = this.websocketOnerror;
            this.websock.onmessage = this.websocketOnmessage;
            this.websock.onclose = this.websocketOnclose;
        },
        websocketOnopen: function () {
            console.log("WebSocket连接成功");
            //心跳检测重置
            //this.heartCheck.reset().start();
        },
        websocketOnerror: function (e) {
            console.log("WebSocket连接发生错误" + e);
            this.reconnect();
        },
        websocketOnmessage: function (e) {
            console.log("-----接收消息-------", e.data);
            // var data = eval("(" + e.data + ")"); //解析对象
            // this.loadData();
            // if(data.cmd == "topic"){
            //     //系统通知
            //   this.loadData();
            // }else if(data.cmd == "user"){
            //     //用户消息
            //   this.loadData();
            // }
            //心跳检测重置
            //this.heartCheck.reset().start();
        },
        websocketOnclose: function (e) {
            console.log("connection closed (" + e + ")");
            if (e) {
                console.log("connection closed (" + e.code + ")");
            }
            this.reconnect();
        },
        websocketSend(text) {
            // 数据发送
            try {
                this.websock.send(text);
            } catch (err) {
                console.log("send failed (" + err.code + ")");
            }
        },
        reconnect() {
            var that = this;
            if (that.lockReconnect) return;
            that.lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            setTimeout(function () {
                console.info("尝试重连...");
                that.initWebSocket();
                that.lockReconnect = false;
            }, 5000);
        },

        async $_validateCustomer() {
            try {
                const res = await this.$axios.get("customer/order/validate");
                if (res.data.success) {
                    const customerStatus = res.data.result.status;
                    switch (customerStatus) {
                        case 1:
                            this.customerStatus = "pass";
                            this.$router.push("/scanner");
                            break;
                        case 2:
                            this.customerStatus = "depositUnpaid";
                            Dialog.confirm({
                                title: "請綁定支付方式",
                                message: "",
                                confirmButtonText: "確認",
                            })
                                .then(() => {
                                    console.log("confirm");
                                    // this.$_bindLightBoxOnpress();
                                    this.$router.push("DepositMethod");
                                })
                                .catch(() => {
                                    console.log("cancel");
                                });

                            break;
                        case 3:
                            this.customerStatus = "orderUnpaid";
                            this.$router.push("/records");
                            break;
                        case 4:
                            this.customerStatus = "orderInUse";
                            Toast("你有未完成的訂單");
                            break;
                        case 5:
                            this.customerStatus = "cardUnbound";
                            // Dialog.confirm({
                            // 	title: "請申請LINE PAY並綁定",
                            // 	message: "",
                            // 	confirmButtonText: "確認",
                            // })
                            // 	.then(() => {
                            // 		console.log("confirm");
                            // 		this.$_bindLightBoxOnpress();
                            // 	})
                            // 	.catch(() => {
                            // 		console.log("cancel");
                            // 	});
                            break;
                    }
                    // if (this.customerStatus !== "pass" && this.customerStatus) {
                    // 	this.$_checkCustomerStatus();
                    // }
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async powerBank(deviceNo) {
            try {
                Toast.loading({
                    message: "正在租借，請稍後...",
                    forbidClick: true,
                });
                const res = await this.$axios.post(
                    "/customer/order/powerbank",
                    {
                        deviceSn: deviceNo,
                    }
                );
                console.log("res: ", res);
                if (res.data.success) {
                    this.loopResult();
                    this.deviceNo = "";
                } else {
                    throw res;
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
        // async $_bindLightBoxOnpress() {
        // 	try {
        // 		const res = await this.$axios.get("linePay/saveKey");
        // 		if (res.data.success) {
        // 			window.location.href = res.data.result;
        // 		} else {
        // 			throw res;
        // 		}
        // 	} catch (error) {
        // 		handleErr({ ...error, app: this.$parent });
        // 	}
        // },
        async checkOrder() {
            try {
                Toast.loading({
                    message: "正在租借，請稍後...",
                    forbidClick: true,
                });
                const res = await this.$axios.get("/customer/order/checkOrder");
                console.log("res: ", res);
                if (res.data.success) {
                    switch (res.data.result.status) {
                        case "0":
                            //失败
                            window.clearInterval(this.timer);
                            Toast("你本次支付失敗，請檢查");
                            break;
                        case "1":
                            Toast.clear();
                            //成功
                            window.clearInterval(this.timer);
                            // this.$router.push("/scanner");
                            this.advImg =
                                process.env.VUE_APP_IMG_URL +
                                res.data.result.advInfo.advImg;
                            console.log("this.advImg: ", this.advImg);
                            this.advUrl = res.data.result.advInfo.advLink;
                            this.showAdDialog(this.advImg, this.advUrl);
                            break;
                        case "2":
                            //繼續
                            break;
                    }
                }
                // else {
                // 	Toast.clear();
                // 	throw res;
                // }
            } catch (error) {
                Toast.clear();
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
        // async checkOrder2() {
        //     try {
        //         // Toast.loading({
        //         //     message: "正在归还，請稍後...",
        //         //     forbidClick: true,
        //         // });
        //         const res = await this.$axios.get(
        //             "/customer/order/checkOrder2"
        //         );
        //         console.log("res: ", res);
        //         if (res.data.success) {
        //             switch (res.data.result.status) {
        //                 case "1":
        //                     window.clearInterval(this.timer2);
        //                     // Toast("行動電源已歸還成功，感謝您的使用");
        //                     // 行動電源已歸還成功，本次消費xx元，感謝您的使用
        //                     Dialog({
        //                         message: `行動電源已歸還成功，本次消費${res.data.result.money||0}元，感謝您的使用`,
        //                         confirmButtonText: "確認",
        //                     });
        //                     break;
        //             }
        //         }
        //     } catch (error) {
        //         Toast.clear();
        //         console.log(error, "store error");
        //         handleErr({ ...error, app: this.$parent });
        //     }
        // },
        $_checkCustomerStatus() {
            if (this.customerStatus === "pass") {
                localStorage.setItem(
                    "devices",
                    JSON.stringify(this.store.devices)
                );
                this.$router.push("/confirm-device");
            } else if (
                this.customerStatus === "cardUnbound" ||
                this.customerStatus === "depositUnpaid"
            ) {
                this.showBindAlert = true;
            } else if (
                this.customerStatus === "orderUnpaid" ||
                this.customerStatus === "orderInUse"
            ) {
                this.showServiceAlert = true;
            }
        },
        beforeDestroy: function () {
            // 页面离开时断开连接,清除定时器
            this.disconnect();
            clearInterval(this.timer);
            clearInterval(this.timer2);
        },
        showAdDialog() {
            this.showAd = true;
        },
        confirm() {
            this.showAd = false;
        },
        openUrl() {
            this.showAd = false;
            window.location.href = this.advUrl;
        },
        loopResult() {
            // console.log("this.count外 : ", this.count);
            this.timer = window.setInterval(() => {
                setTimeout(() => {
                    this.count++;
                    console.log("this.count內: ", this.count);
                    if (this.count <= 30) {
                        this.checkOrder();
                    }
                }, 0);
            }, 2000);
        },
        // loopResultBack() {
        //     this.timer2 = window.setInterval(() => {
        //         setTimeout(() => {
        //             this.checkOrder2();
        //         }, 0);
        //     }, 2000);
        // },
        async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log("res: ", res);
                if (res.data.success) {
                    const subscribe =
                        res.data.result.subscribe == true ? "1" : "0";
                    const money = res.data.result.money;
                    const startExpireDate = res.data.result.startExpireDate;
                    const freeHour = res.data.result.freeHour;
                    const bindCardStatus =
                        res.data.result.bindCardStatus == true ? "1" : "0";
                    const expireDate = res.data.result.expireDate;
                    const avatar = res.data.result.avatar;
                    localStorage.setItem("avatar", avatar);
                    localStorage.setItem("subscribe", subscribe);
                    console.log("subscribe: ", subscribe);
                    localStorage.setItem("money", money);
                    localStorage.setItem("startExpireDate", startExpireDate);
                    localStorage.setItem("freeHour", freeHour);
                    localStorage.setItem("bindCardStatus", bindCardStatus);
                    console.log("bindCardStatus: ", bindCardStatus);
                    localStorage.setItem("expireDate", expireDate);
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
          // 定义一个函数来记录事件
        logCustomEvent(name, params) {
            logEvent(analytics, name, params);
        },

    },
    beforeDestroy() {
        // clearInterval(this.timer2);
    },
};
</script>
<style scoped lang="less">
.confirm {
    background: #2c394e;
    border-radius: 25px;
    height: 50px;
    color: #ffffff;
    line-height: 50px;
    margin: 20px;
    text-align: center;
}
.box {
    position: absolute;
    top: 16px;
    right: 16px;
    left: 16px;
    height: 124px;
}
</style>
